
import { Vue, Component, Ref } from "vue-property-decorator";
import { UserListDataSource } from "@/data/List/UserListDataSoure";
import RoleEditorDialog from "@/pages/manage/users/components/role-editor-dialog.vue";
import ResetPasswordDialog from "@/pages/manage/users/components/reset-password-dialog.vue";
import { UserObjectDataSource } from "@/data/Object/UserObjectDataSource";
import { mdiDownload, mdiPlusCircleOutline, mdiUpload } from "@mdi/js";
import FileUploaderDialog from "@/components/helpers/file-uploader-dialog.vue";
import DataList from "@/components/common/data/list.vue";
@Component({
  components: { RoleEditorDialog, ResetPasswordDialog, FileUploaderDialog },
})
export default class UserList extends Vue {
  headers: Array<any> = [
    {
      text: "ID",
      value: "id",
      sortable: true,
      width: 100,
    },
    {
      text: "Дата создания",
      value: "createDate",
      sortable: false,
    },
    {
      text: "Email",
      value: "email",
      sortable: false,
    },
    {
      text: "ФИО",
      value: "fullName",
      sortable: false,
    },

    {
      text: "Подтверждение",
      value: "emailConfirmed",
      sortable: false,
    },

    {
      text: "Статус",
      value: "instanceStateId",
      sortable: false,
    },
  ];
  @Ref("dataList") dataList!: DataList;
  dataSource: UserListDataSource = new UserListDataSource({
    pageIndex: 1,
    pageSize: 15,
  });
  uploaderDialogVisible: boolean = false;
  userObjectDataSource: UserObjectDataSource | null = null;

  createItemFields: any = [
    {
      editor: "string",
      name: "lastName",
      config: {},
      attrs: {
        type: "text",
        label: "Фамилия",
      },
      validations: [
        {
          validator: "max",
          value: 150,
          errorMessage: "Поле не может содержать больше чем 150 символов",
        },
        {
          validator: "required",
          value: true,
          errorMessage: "Поле не может быть пустым",
        },
      ],
    },
    {
      editor: "string",
      name: "firstName",
      config: {},
      attrs: {
        type: "text",
        label: "Имя",
      },
      validations: [
        {
          validator: "max",
          value: 150,
          errorMessage: "Поле не может содержать больше чем 150 символов",
        },
        {
          validator: "required",
          value: true,
          errorMessage: "Поле не может быть пустым",
        },
      ],
    },
    {
      editor: "string",
      name: "patronymic",
      config: {},
      attrs: {
        type: "text",
        label: "Отчество",
      },
    },
    {
      editor: "string",
      name: "email",
      config: {},
      attrs: {
        type: "text",
        label: "Почта",
      },
      validations: [
        {
          validator: "required",
          value: true,
          errorMessage: "Поле не может быть пустым",
        },
        {
          validator: "regular",
          value: "email",
          errorMessage: "Пожалуйста введите корректный email",
        },
      ],
      watchers: [
        {
          changeModel: "synhronUserName",
        },
      ],
    },
    {
      editor: "password",
      attrs: {
        label: "Пароль",
        hint: "Введите минимум 8 символов",
        class: "mb-4",
      },
      name: "password",
      validations: [
        {
          validator: "required",
          errorMessage: "поле не может быть пустым",
        },
        {
          validator: "min",
          value: 7,
          errorMessage: "Введите минимум 8 символов",
        },
      ],
    },
  ];

  async confirmEmail(userProfile: any) {
    this.userObjectDataSource = new UserObjectDataSource(userProfile.id);
    await this.userObjectDataSource.confirmEmail(userProfile.id);
    this.$message("Почта успешно подверждён");
  }

  async sendConfirmMessage(userProfile: any) {
    this.userObjectDataSource = new UserObjectDataSource(userProfile.id);
    await this.userObjectDataSource.sendConfirmMessage(userProfile.id);
    this.$message("Письмо с подверждением успешно отправлено на почту");
  }

  createItemActions: any = {
    synhronUserName: (model) => {
      model.userName = model.email;
    },
  };

  createItemModel: any = {
    lastName: null,
    firstName: null,
    email: null,
    password: null,
    userName: null,
  };
  $message: any;
  $confirm: any;
  icons: any = {
    circlePlus: mdiPlusCircleOutline,
    upload: mdiUpload,
    download: mdiDownload,
  };
  roleEditorDialogVisible: boolean = false;
  resetPasswordDialogVisible: boolean = false;

  async downloadExcel() {
    try {
      await this.dataSource.downloadExcel();
    } catch (error) {
      this.$message((error as any)?.response?.data, "error");
    }
  }

  async uploadFile(event: any) {
    try {
      await this.dataSource.uploadCsv(event);
      await this.dataList.refreshData();
      this.uploaderDialogVisible = false;
      this.$message("Успешно загружено");
    } catch (error) {
      this.$message((error as any)?.response?.data, "error");
    }
  }

  async remove(item: any) {
    const confirm = await this.$confirm(
      "Вы действительно хотите удалить данного пользователя",
    );
    if (!confirm) return;
    try {
      await this.dataSource.remove(item.id);
      this.$message("Успешно удалён");
    } catch (error: any) {
      this.$message(error?.response.data, "error");
    }
  }

  get breadcrumbs() {
    return [
      {
        text: "Главная",
        to: "/",
      },
      {
        text: "Пользователи",
        disabled: true,
      },
    ];
  }

  async editRole(item) {
    this.roleEditorDialogVisible = false;
    this.userObjectDataSource = new UserObjectDataSource(item.id);
    await this.userObjectDataSource.getRoles();
    this.roleEditorDialogVisible = true;
  }

  resetPassword(item) {
    this.resetPasswordDialogVisible = false;
    this.userObjectDataSource = new UserObjectDataSource(item.id);
    this.resetPasswordDialogVisible = true;
  }
}
